import { Form, Link, useRouteError } from '@remix-run/react'
import { motion } from 'framer-motion'
import ErrorMessage from '~/components/error-message'
import { Button } from '~/components/ui/button'

const Route = () => {
  return (
    <div>
      <div className='flex flex-col max-w-3xl mx-auto text-center'>
        <motion.section
          initial={{ y: 0, opacity: 0 }}
          animate={{ y: '20px', opacity: 1 }}
          transition={{ duration: 0.7, ease: 'easeInOut' }}
        >
          <h1 className='text-6xl font-bold '>
            Simplify your{' '}
            <span className='bg-gradient-to-r from-blue-500 to-purple-500 text-transparent bg-clip-text'>
              prepaid hour
            </span>{' '}
            management
          </h1>
        </motion.section>
        <motion.section
          initial={{ y: 0, opacity: 0 }}
          animate={{ y: '20px', opacity: 1 }}
          transition={{ duration: 0.7, ease: 'easeInOut', delay: 0.2 }}
        >
          <p className='text-lg my-8'>
            Effortlessly track and manage prepaid hours for every client. Log
            time, monitor open hours, and export reports in a snap—perfect for
            freelancers who value time and transparency.
          </p>
        </motion.section>
        <div className='flex gap-4 justify-center'>
          <Form method='post' action='/resource/demo-login'>
            <Button type='submit' className='mt-8 px-6 py-6' variant='outline'>
              Log in with demo account
            </Button>
          </Form>
          <Link to='/signup'>
            <Button className='mt-8 px-6 py-6 bg-gradient-to-br from-blue-500 to-purple-500 dark:border-none dark:text-white'>
              Sign up
            </Button>
          </Link>
        </div>
      </div>
      <img
        src='/app-preview.png'
        alt='Screenshot'
        className='w-2/3 mx-auto mt-12 rounded-lg'
      />
    </div>
  )
}

export default Route

export function ErrorBoundary() {
  const error = useRouteError() as Error
  console.log(error)
  return <ErrorMessage error={error} />
}
